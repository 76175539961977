<template>
  <v-container>
    <v-row>
      <v-col
        cols="12"
        :md="showHelp ? 8 : 11"
        :sm="12"
        v-show="!showExpand"
        class="pr-0"
      >
        <GoBackBtn :routeToGo="routeToGo" :tabId="false" />
        <PageHeader :title="title" class="pb-2" />
        <v-form v-model="isFormValid" @submit.prevent="saveParameters()">
          <v-row>
            <v-col class="py-0" cols="12" sm="12" lg="6">
              <v-card>
                <v-card-title>Parámetros generales</v-card-title>
                <v-card-text>
                  <v-row>
                    <v-col cols="4" class="pb-0">
                      <v-text-field
                        dense
                        outlined
                        label="Edad mínima"
                        :rules="
                          rules.requiredAcceptZero.concat([
                            rules.minMaxNumber(edadMinima, 0, 99)
                          ])
                        "
                        v-model="edadMinima"
                        suffix="años"
                        class="widthPar1"
                        onkeypress="return (event.charCode >= 48 && event.charCode <= 57)"
                      />
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col class="py-0">
                      <p class="d-flex align-center mb-0">
                        ¿Permitir registros sin ingresar email?
                        <v-checkbox
                          v-model="ingresarEmail"
                          @change="cambiarEmail"
                        />
                      </p>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col class="py-0">
                      <p class="d-flex align-center mb-0">
                        ¿Permitir editar el email si se encuentra previamente
                        cargado?
                        <v-checkbox
                          :disabled="ingresarEmail === false"
                          v-model="editarEmailCarg"
                        />
                      </p>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col class="d-flex py-0">
                      <p class="d-flex align-center mb-0 mr-3">
                        Permite al afiliado editar:
                      </p>
                      <p class="d-flex align-center mb-0 mr-3">
                        <v-checkbox v-model="editarDomicilio" />Domicilio
                      </p>
                      <p class="d-flex align-center mb-0 mr-3">
                        <v-checkbox v-model="editarTelefono" />Teléfono
                      </p>
                      <p class="d-flex align-center mb-0 mr-3">
                        <v-checkbox v-model="editarEmail" />Email
                      </p>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col class="py-0">
                      <v-text-field
                        outlined
                        dense
                        label="Link de ayuda de acceso (Android)"
                        v-model="linkLoginAndroid"
                      />
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col class="py-0">
                      <v-text-field
                        outlined
                        dense
                        label="Link de ayuda de registro (Android)"
                        v-model="linkSignupAndroid"
                      />
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col class="py-0">
                      <v-text-field
                        outlined
                        dense
                        label="Link de ayuda de acceso (IOS)"
                        v-model="linkLoginIos"
                      />
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col class="py-0">
                      <v-text-field
                        outlined
                        dense
                        label="Link de ayuda de registro (IOS)"
                        v-model="linkSignupIos"
                      />
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
              <!-- DIVISOR -->
              <v-card>
                <v-card-title>Notificaciones</v-card-title>
                <v-card-text>
                  <v-row>
                    <v-col class="pb-0">
                      <v-select
                        :items="tiempo"
                        item-text="label"
                        item-value="value"
                        outlined
                        dense
                        v-model="minsNotif"
                        label="Enviar cada"
                      >
                      </v-select>
                    </v-col>
                    <v-col class="pb-0">
                      <v-menu
                        ref="menuHoraDesde"
                        v-model="menuHoraDesde"
                        :close-on-content-click="false"
                        :return-value.sync="timeDesde"
                        transition="scale-transition"
                        offset-y
                        :nudge-bottom="0"
                        max-width="290px"
                        min-width="290px"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                            v-model="timeDesde"
                            label="Hora desde"
                            :append-icon="timeIcon"
                            outlined
                            dense
                            readonly
                            hint="Formato HH:MM"
                            @focus="
                              (menuHoraDesde = true), (menuFechaDesde = false)
                            "
                            @input="menuHoraDesde = false"
                            @blur="$refs.menuHoraDesde.save(timeDesde)"
                            v-bind="attrs"
                            v-on="on"
                          ></v-text-field>
                        </template>
                        <v-time-picker
                          scrollable
                          no-title
                          v-if="menuHoraDesde"
                          v-model="timeDesde"
                          class="mt-4"
                          format="24hr"
                          @click:minute="$refs.menuHoraDesde.save(timeDesde)"
                        ></v-time-picker>
                      </v-menu>
                    </v-col>
                    <v-col class="pb-0">
                      <v-menu
                        ref="menuHoraHasta"
                        v-model="menuHoraHasta"
                        :close-on-content-click="false"
                        :return-value.sync="timeHasta"
                        transition="scale-transition"
                        offset-y
                        :nudge-bottom="0"
                        max-width="290px"
                        min-width="290px"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                            v-model="timeHasta"
                            label="Hora hasta"
                            :append-icon="timeIcon"
                            outlined
                            dense
                            readonly
                            hint="Formato HH:MM"
                            @focus="
                              (menuHoraHasta = true), (menuFechaHasta = false)
                            "
                            @input="menuHoraHasta = false"
                            @blur="$refs.menuHoraHasta.save(timeHasta)"
                            v-bind="attrs"
                            v-on="on"
                          ></v-text-field>
                        </template>
                        <v-time-picker
                          scrollable
                          no-title
                          v-if="menuHoraHasta"
                          v-model="timeHasta"
                          class="mt-4"
                          format="24hr"
                          @click:minute="$refs.menuHoraHasta.save(timeHasta)"
                        ></v-time-picker>
                      </v-menu>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-col>
            <!-- DIVISOR -->
            <v-col class="py-0" cols="12" sm="12" lg="6">
              <v-card>
                <v-card-title
                  >Consulta de historial de autorizaciones</v-card-title
                >
                <v-card-text>
                  <v-row>
                    <v-col cols="1" class="d-flex align-center pb-0">
                      <p class="mb-6">Área</p>
                    </v-col>
                    <v-col cols="5" class="pb-0">
                      <v-select
                        :items="areas"
                        item-text="label"
                        item-value="value"
                        class="ml-3"
                        outlined
                        dense
                        v-model="areaHistorial"
                      ></v-select>
                    </v-col>
                    <v-col cols="6" class="pb-0">
                      <v-text-field
                        onkeypress="return (event.charCode >= 48 && event.charCode <= 57)"
                        dense
                        outlined
                        :rules="
                          rules.positiveNumberHigher0.concat(rules.max99999)
                        "
                        label="Días de consulta"
                        v-model="diasConsultaAutorizaciones"
                        suffix="días"
                      />
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12" class="py-0">
                      <p class="d-flex align-center mb-0">
                        ¿Permitir descarga de autorizaciones?
                        <v-checkbox
                          class="my-0 pt-5"
                          v-model="permitirDescarga"
                        />
                      </p>
                    </v-col>
                    <v-col cols="12" class="py-0 ">
                      <p class="d-flex align-center mb-0">
                        ¿Excluir solicitudes de reintegros?
                        <v-checkbox
                          class="my-0 pt-5"
                          v-model="excluirSolicitudesReintegros"
                          :append-icon="infoIcon"
                          ><template v-slot:append>
                            <v-tooltip right max-width="10rem">
                              <template v-slot:activator="{ on, attrs }">
                                <v-icon size="1.4rem" v-on="on" v-bind="attrs">
                                  {{ infoIcon }}</v-icon
                                >
                              </template>
                              <span
                                >Excluye del historial las autorizaciones cuyo
                                origen tiene la marca 'Es reintegro'</span
                              >
                            </v-tooltip>
                          </template>
                        </v-checkbox>
                      </p>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
              <!-- DIVISOR -->
              <v-card>
                <v-card-title>Cuenta corriente</v-card-title>
                <v-card-text>
                  <v-row>
                    <v-col class="d-flex pb-0">
                      <v-text-field
                        onkeypress="return (event.charCode >= 48 && event.charCode <= 57)"
                        dense
                        outlined
                        :rules="
                          minsCobrosPendientes
                            ? rules.positiveNumber.concat(rules.max99999)
                            : []
                        "
                        label="Registrar cobros pendientes cada"
                        v-model="minsCobrosPendientes"
                        suffix="mins"
                        class="mr-2"
                      />
                      <v-text-field
                        onkeypress="return (event.charCode >= 48 && event.charCode <= 57)"
                        dense
                        outlined
                        :rules="
                          rules.positiveNumberHigher0.concat(rules.max99999)
                        "
                        label="Días de consulta de saldo"
                        v-model="diasConsultaCtaCte"
                        suffix="días"
                        class="ml-2"
                      />
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="4" md="6" sm="5" class="py-0">
                      <p class="d-flex align-center">
                        ¿Permitir consulta de pagos?
                        <v-checkbox
                          class="my-0 pt-5"
                          v-model="consultaPagos"
                          @change="cambiarDiasCons"
                        />
                      </p>
                    </v-col>
                    <v-col cols="8" md="6" sm="7" class="pl-0">
                      <v-text-field
                        ref="consulta-pagos"
                        onkeypress="return (event.charCode >= 48 && event.charCode <= 57)"
                        dense
                        outlined
                        :rules="
                          this.consultaPagos
                            ? rules.positiveNumberHigher0.concat(rules.max99999)
                            : []
                        "
                        :disabled="consultaPagos === false"
                        label="Días de consulta de pagos"
                        class="ml-2"
                        v-model="diasConsultaCtaCtePagos"
                        suffix="días"
                      />
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col class="py-0">
                      <v-text-field
                        outlined
                        dense
                        :rules="rules.url"
                        label="WS Gecros URL"
                        v-model="urlWSGecros"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col class="pt-0">
                      <p>
                        Información de medios de pago
                        <v-btn
                          class="ml-4"
                          fab
                          small
                          color="primary"
                          @click="openInfoPago"
                        >
                          <v-icon>{{ editIcon }}</v-icon>
                        </v-btn>
                      </p>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
              <v-card>
                <v-card-title>Configuración de cartilla</v-card-title>
                <v-card-text>
                  <v-row>
                    <v-col class="pr-0 pb-0">
                      <v-select
                        outlined
                        clearable
                        dense
                        label="Prestadores"
                        v-model="selectDomicilioPrestador"
                        class="mr-2"
                        :items="domiciliosPrestadores"
                        item-text="value"
                        item-value="id"
                      />
                    </v-col>
                    <v-col class="pl-0 pb-0">
                      <v-select
                        outlined
                        clearable
                        dense
                        label="Farmacias"
                        v-model="selectDomicilioFarmacia"
                        class="ml-2"
                        :items="domiciliosPrestadores"
                        item-text="value"
                        item-value="id"
                      />
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col class="py-0">
                      <v-text-field
                        dense
                        outlined
                        v-model="geocodingKey"
                        label="Geocoding key"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12" class="py-0">
                      <v-switch
                        class="my-0"
                        v-model="filtroProv"
                        label="Utiliza filtro por provincia"
                      ></v-switch>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </v-form>
        <v-card-actions class="justify-end">
          <v-btn
            @click="saveParameters"
            :disabled="!isFormValid"
            color="primary"
          >
            Actualizar
          </v-btn>
        </v-card-actions>
      </v-col>
      <!-- Texto de ayuda principal -->
      <v-col
        cols="12"
        :md="!showHelp ? 1 : showExpand ? 12 : 4"
        class="to-right pt-7 px-0 "
      >
        <Ayuda
          :optionCode="optionCode"
          :pantallaTitle="title"
          @toggleExpand="showExpand = !showExpand"
          @toggleHelp="toggleHelp()"
          @toggleIcon="toggleIcon()"
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import rules from "@/utils/helpers/rules.js";
import PageHeader from "@/components/ui/PageHeader";
import { mapActions } from "vuex";
import enums from "@/utils/enums/index.js";
import GoBackBtn from "@/components/shared/GoBackBtn";
import Ayuda from "@/components/shared/Ayuda.vue";

export default {
  components: {
    PageHeader,
    GoBackBtn,
    Ayuda
  },
  name: "ParametrosGeneralesApp",
  data() {
    return {
      infoIcon: enums.icons.SNB_INFO,
      rules: rules,
      isFormValid: true,
      timeDesde: new Date(new Date().valueOf() - 1000 * 60 * 60 * 3)
        .toISOString()
        .substr(11, 12),
      timeHasta: new Date(new Date().valueOf() - 1000 * 60 * 60 * 3)
        .toISOString()
        .substr(11, 12),
      menuFechaDesde: false,
      menuFechaHasta: false,
      menuHoraDesde: false,
      menuHoraHasta: false,
      filtroProv: false,
      routeToGo: "ConfiguracionAppBenef",
      edadMinima: null,
      ingresarEmail: false,
      editarEmailCarg: false,
      editarDomicilio: false,
      editarTelefono: false,
      editarEmail: false,
      linkLoginAndroid: null,
      linkSignupAndroid: null,
      linkLoginIos: null,
      linkSignupIos: null,
      minsNotif: null,
      ingresarEmail: false,
      areaHistorial: null,
      diasConsultaAutorizaciones: null,
      permitirDescarga: false,
      excluirSolicitudesReintegros: false,
      diasConsultaCtaCte: null,
      consultaPagos: false,
      diasConsultaCtaCtePagos: null,
      minsCobrosPendientes: 0,
      urlWSGecros: null,
      geocodingKey: null,
      selectDomicilioFarmacia: null,
      selectDomicilioPrestador: null,
      domicilioPrestador: [],
      domicilioFarmacia: [],
      areas: [
        { value: "D", label: "Dual" },
        { value: "A", label: "Ambulatorio" },
        { value: "I", label: "Internado" }
      ],
      tiempo: [
        { value: 5, label: "5 mins" },
        { value: 10, label: "10 mins" },
        { value: 15, label: "15 mins" },
        { value: 30, label: "30 mins" },
        { value: 60, label: "1 hora" },
        { value: 120, label: "2 horas" },
        { value: 360, label: "6 horas" },
        { value: 720, label: "12 horas" },
        { value: 1440, label: "1 día" }
      ],
      domiciliosPrestadores: [],
      timeIcon: enums.icons.TIME,
      title: enums.titles.CONFIGURACION_PARAMETROS_GENERALES,
      optionCode: enums.webSiteOptions.PARAMETROS_GENERALES_APP,
      showHelp: false,
      showExpand: false,
      showIcon: true,
      editIcon: enums.icons.EDIT
    };
  },
  created() {
    this.$store.dispatch("user/changeAppLoading", {
      status: false,
      text: "",
      opaque: false
    });
    this.loadParameters();
  },
  methods: {
    ...mapActions({
      getParameters: "configAppBenef/getParameters",
      postParameters: "configAppBenef/postParameters",
      getOpcionPrestadores: "configAppBenef/getOpcionPrestador",
      setAlert: "user/setAlert"
    }),
    async loadParameters() {
      const data = await this.getParameters();
      this.domiciliosPrestadores = await this.getOpcionPrestadores();
      this.edadMinima = data.edadMinimaUsarApp;
      this.ingresarEmail = data.permiteRegistroSinEmail;
      this.editarEmailCarg = data.permiteSobrescribirEmailEnRegistro;
      this.editarDomicilio = data.permiteEditarDomicilio;
      this.editarTelefono = data.permiteEditarTelefonos;
      this.editarEmail = data.permiteEditarEmail;
      this.linkLoginAndroid = data.linkAyudaLoginAndroid;
      this.linkSignupAndroid = data.linkAyudaSignupAndroid;
      this.linkLoginIos = data.linkAyudaLoginIos;
      this.linkSignupIos = data.linkAyudaSignupIos;
      this.minsNotif = data.sendNotificationsEvery;
      this.areaHistorial = data.areaConsultaAutorizaciones;
      this.diasConsultaAutorizaciones = data.diasConsultaAutorizaciones;
      this.permitirDescarga = data.descargaAutorizaciones;
      this.excluirSolicitudesReintegros = data.excluirSolicitudesReintegros;
      this.diasConsultaCtaCte = data.diasConsultaCtaCte;
      this.consultaPagos = data.consultaPagos;
      this.diasConsultaCtaCtePagos = data.diasConsultaCtaCtePagos;
      this.minsCobrosPendientes = data.registrarCobrosPendientesEvery;
      this.timeDesde = this.setTime(data.sendNotificationsFromHour);
      this.timeHasta = this.setTime(data.sendNotificationsToHour);
      this.urlWSGecros = data.wsGecrosUrl;
      this.contentHTMl = data.infoMediosDePago;
      this.geocodingKey = data.geocodingKey;
      this.selectDomicilioPrestador = data.opcionMostrarDomicilioPrestador;
      this.selectDomicilioFarmacia = data.opcionMostrarDomicilioFarmacia;
      this.filtroProv = data.filtrarCartillaPorProvincia;
    },
    async saveParameters() {
      const data = {
        edadMinimaUsarApp: this.edadMinima,
        permiteRegistroSinEmail: this.ingresarEmail,
        permiteSobrescribirEmailEnRegistro: this.editarEmailCarg,
        permiteEditarDomicilio: this.editarDomicilio,
        permiteEditarTelefonos: this.editarTelefono,
        permiteEditarEmail: this.editarEmail,
        linkAyudaLoginAndroid: this.linkLoginAndroid,
        linkAyudaSignupAndroid: this.linkSignupAndroid,
        linkAyudaLoginIos: this.linkLoginIos,
        linkAyudaSignupIos: this.linkSignupIos,
        sendNotificationsEvery: this.minsNotif,
        areaConsultaAutorizaciones: this.areaHistorial,
        diasConsultaCtaCte: this.diasConsultaCtaCte,
        descargaAutorizaciones: this.permitirDescarga,
        excluirSolicitudesReintegros: this.excluirSolicitudesReintegros,
        diasConsultaAutorizaciones: this.diasConsultaAutorizaciones,
        consultaPagos: this.consultaPagos,
        diasConsultaCtaCtePagos: this.diasConsultaCtaCtePagos,
        registrarCobrosPendientesEvery: this.minsCobrosPendientes,
        sendNotificationsFromHour: this.timeDesde,
        sendNotificationsToHour: this.timeHasta,
        wsGecrosUrl: this.urlWSGecros,
        geocodingKey: this.geocodingKey,
        opcionMostrarDomicilioPrestador: this.selectDomicilioPrestador,
        opcionMostrarDomicilioFarmacia: this.selectDomicilioFarmacia,
        filtrarCartillaPorProvincia: this.filtroProv
      };
      const response = await this.postParameters(data);
      if (response.status === 200)
        this.setAlert({ type: "success", message: "Guardado con éxito" });
    },
    setTime(date) {
      const finalDate = date.slice(11, 16);
      return finalDate;
    },
    cambiarEmail() {
      if (this.ingresarEmail === false) this.editarEmailCarg = false;
    },
    cambiarDiasCons() {
      if (this.consultaPagos == false) this.$refs["consulta-pagos"].reset();
    },
    openInfoPago() {
      this.$router.push({
        name: "InformacionMediosdePagos",
        params: { contentProp: this.contentHTMl }
      });
    },
    toggleHelp() {
      this.showHelp = !this.showHelp;
      this.showHelp ? [] : (this.showExpand = false);
    },
    toggleIcon() {
      this.showIcon = true;
    }
  }
};
</script>

<style scoped>
.v-card {
  margin: 2% 0;
}
.v-input--checkbox {
  margin-left: 3%;
}
::v-deep input::-webkit-outer-spin-button,
::v-deep input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
::v-deep input[type="number"] {
  -moz-appearance: textfield;
}
</style>
